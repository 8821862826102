<template>
    <div class="main-container">
        <el-config-provider :locale="currentLocale">
            <RouterView />
        </el-config-provider>
    </div>
</template>

<script setup lang="ts">
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'
import { useLangStore } from '@/store/i18n'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { UserApi } from './utils/api/modules'
const langStore = useLangStore()
const currentLocale = ref(langStore.language === 'zh' ? zhCn : en)
const router = useRouter()
const { t, locale } = useI18n()
locale.value = langStore.language
// const getUserSystem = async () => {
//     try {
//         const res = await UserApi.getUserSystem()
//         res.data.language == 'zh_cn'
//             ? (langStore.language = 'zh')
//             : (langStore.language = 'en')
//     } catch (error) {
//         console.error('获取用户系统设置失败:', error)
//     }
// }
router.beforeEach(async (to) => {
    // 查询用户系统设置的语言
    // await getUserSystem()
    if (to.meta.title) {
        to.meta.title = t(to.meta.title as string) // 设置页面标题
    }
})

watch(langStore, (newNmuber, _oldVal) => {
    currentLocale.value = newNmuber.language === 'zh' ? zhCn : en
    locale.value = newNmuber.language
})
</script>
<style scoped>
.main-container {
    /* display: flex; */
    height: 100%;
    width: 100%;
    /* justify-content: space-between; */
}
</style>
